<template>
  <div v-if="showSleepMessage" class="sleep-message">Sleeping...</div>
  <div v-else class="main-container">
    <Menu v-if="curPlayerData && curPlayerData.id" />
    <!-- <router-view :key="$route.path" /> -->
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import Menu from './components/Menu.vue'; 
import { useStore } from 'vuex'

export default {
  components: { Menu },
  setup() {
    const store = useStore()
    const curPlayerData = computed(() => store.getters.curPlayerData);

    const mainContentClass = computed(() => {
      return {
        'main-content': curPlayerData.id,
        'main-content-no-navbar': !curPlayerData.id,
      }
    })

    const version = ref(null)
    const showSleepMessage = ref(false)
    let timer = null

    // New code: Handle visibility changes, when user minimizes browser it shuts down the app then force refreshes on wakeup
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Start timer when tab is not active
        timer = setTimeout(() => {
          showSleepMessage.value = true
          store.commit('setAppSleeping', true)
        }, 90 * 60 * 1000) // 90 minutes
      } else {
        // Clear timer and refresh the page when tab becomes active
        clearTimeout(timer)
        if (showSleepMessage.value) {
          showSleepMessage.value = false
          store.commit('setAppSleeping', false)
          // alert('Waking up...');
          location.reload() // Force a refresh
        }
      }
    }
    onMounted(async () => {
      // New code: Add event listener for visibility change
      document.addEventListener('visibilitychange', handleVisibilityChange)
    })

    onUnmounted(() => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    })

    return {
      mainContentClass,
      version,
      showSleepMessage,
      curPlayerData,
    }
  },
}
</script>

<style scoped>
.sleep-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  z-index: 10000;
}
</style>